<template>
  <v-card width="100%" tile height="100%" class="pa-0 ma-0">
    <v-container fluid class="pa-0" fill-height>
      <v-row align="stretch" class="fill-height">
        <v-col cols="12" md="4" order="2" order-md="1">
          <v-row class="fill-height" align="center" justify="center">
            <v-col>
              <v-card color="white" class="mx-auto px-10" flat max-width="100%">
                <div class="text-center pa-5">
                  <img src="@/assets/logo.png" width="80%" />
                </div>
                <div class="px-5 mb-10">
                  <h1 class="text-color-1">Bem Vindo!</h1>
                  <p class="caption text-color-2">
                    Monitore o fluxo dos dados dentro da sua empresa com
                    agilidade e organização, com toda a fundamentação jurídica
                    da LGPD.
                  </p>
                </div>
                <div class="px-5 pb-5">
                  <v-form v-model="valid" ref="form">
                    <b class="caption text-color-2">Endereço de E-mail</b>
                    <v-text-field
                      v-model="user.email"
                      @keyup.enter="login"
                      placeholder="E-mail"
                      prepend-inner-icon="mdi-account"
                      :rules="[(v) => !!v || 'O Campo é necessário', emailRule]"
                      :error="error"
                      outlined
                    ></v-text-field>
                    <b class="caption text-color-2">Sua Senha</b>
                    <v-text-field
                      v-model="user.password"
                      @keyup.enter="login"
                      placeholder="Senha"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-key"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="[(v) => !!v || 'O Campo é necessário']"
                      :error="error"
                      outlined
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-form>
                </div>
                <div class="py-10 small-text text-right">
                  <v-btn depressed color="primary" @click="login()" class="mr-2"
                    >Entrar</v-btn
                  >
                  <v-btn depressed outlined color="primary" to="/register"
                    >Criar Conta</v-btn
                  >
                  <div class="mt-5">
                    Esqueceu a senha?
                    <v-btn text color="primary" x-small to="/rescue"
                      >Recuperar</v-btn
                    >
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="text-center pa-0"
          order="1"
          order-md="2"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-card
            color="primary"
            tile
            height="100%"
            class="pa-10 mx-auto"
            flat
            dark
          >
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col>
                <h1>Gestão de Privacidade e Segurança de Dados</h1>
                <h3>
                  Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD)
                </h3>
                <img
                  src="@/assets/lgpd_digisystem_ibm.png"
                  style="max-width:100%;"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'

export default {
  name: 'Login',
  components: {},
  data: () => ({
    user: {
      email: '',
      password: '',
    },
    showPassword: false,
    valid: false,
    error: false,
    emailRule: (v) => /.+@.+\..+/.test(v) || 'É preciso um e-mail válido',
  }),
  methods: {
    login() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text:
            'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      this.$store.dispatch('setLoadingInfo', true)
      this.error = false
      const url = `${baseApiUrl}/login`
      axios
        .post(url, this.user)
        .then((res) => {
          const userLogged = JSON.stringify(res.data)
          localStorage.setItem('user', userLogged)

          this.$store.dispatch('setUserInfo', res.data)
          this.loadMenus()

          this.$router.push({ path: '/' })
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', false)
        })
    },
    loadMenus() {
      const url = `${baseApiUrl}/menu/header`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setMenuInfo', res.data)
        })
        .catch(showError)
    },
  },
}
</script>